body{
    background-color: white;
}

.container{
    min-width:100%;
    min-height:100%;
    position: fixed;
    top: 0; 
    left: 0;
    bottom: 0; 
    left: 0;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: white;
}


.containerInfo{
    min-width:100%;
    min-height:100%;
    /* position: fixed; */
    top: 0; 
    left: 0;
    bottom: 0; 
    left: 0;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: white;
}
